.settingsMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--border-radius);
  background: var(--white, #fff);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  width: 260px;
  min-height: 216px;

  top: 32px;

  padding: 5px;

  gap: 2px;

  position: absolute;

  z-index: var(--zIndexModals);
}

.settingsMenuItem {
  display: flex;
  width: 250px;
  height: 34px;
  padding: 9px 6px 9px 12px;
  align-items: center;
  gap: 8px;

  position: relative;

  cursor: pointer;

  border-radius: var(--border-radius);
  background: var(--white, #fff);

  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--text70, #69696b);

  svg path {
    stroke: var(--text70, #69696b);
  }

  &:hover {
    color: var(--text-icons-black, #29282c);
    background: var(--background-gray-hover, #ededee);
    svg path {
      stroke: var(--text-icons-black, #29282c);
    }
  }

  &__black {
    svg path {
      stroke: none;
    }
    color: var(--text-icons-black, #29282c);

    &:hover {
      svg path {
        stroke: none;
      }
    }
  }
}

.secondaryInlineIcon {
  position: absolute;
  right: 8px;
}

.settingsMenuDivider {
  width: 240px;
  height: 1px;
  background: var(--text10, #eaeaea);

  margin: 3px 0px;
}

.settingsMenuWorkspaceListTitle {
  margin: 7px 0px;
  width: 234px;

  display: flex;
  justify-content: flex-start;

  cursor: text;

  color: var(--text40, #a9a9ab);
  font-family: Euclid Circular A;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;

  text-transform: uppercase;
}
