.error-boundary {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-boundary__container {
  display: flex;
  flex-direction: column;

  align-items: center;
}

.error-boundary__title {
  font-family: 'Euclid Circular A';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;

  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #29282c;
}

.error-boundary__info__button {
  margin-top: 46px;
}
