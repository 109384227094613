.sidebarTopActions {
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  gap: 8px;
}

.sidebarTopActionsButton {
  display: flex;
  width: 240px;
  height: 34px;
  padding: 9px 6px 9px 12px;
  align-items: center;
  gap: 8px;

  position: relative;

  color: var(--text70);
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  border-radius: var(--border-radius);

  cursor: pointer;

  & > svg path {
    stroke: var(--text70);
  }

  // &:hover {
  //   color: var(--text-icons-black);
  //   background: var(--background-gray-hover);
  //   & > svg path {
  //     stroke: var(--text-icons-black);
  //   }
  // }
}

.sidebarTopActionsButtonDarkActive {
  background-color: var(--text90) !important;
  color: var(--white) !important;
  & > svg path {
    stroke: var(--white) !important;
  }
}

.sidebarTopActionsButtonDark {
  color: var(--white);
  background-color: var(--text80);

  & > svg path {
    stroke: var(--white);
  }

  // &:hover {
  //   @extend .sidebarTopActionsButtonDarkActive;
  // }
}

.sidebarTopActionsButtonNewMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 240px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  gap: 2px;

  top: 42px;
  left: 0px;

  border-radius: var(--border-radius);

  background-color: var(--white);

  $shadowColor: var(--text-icons-black) + '1a';
  box-shadow: 0 1px 10px #{$shadowColor};

  z-index: var(--zIndexModals);
}

.sidebarTopActionsButtonNewMenuItem {
  @extend .sidebarTopActionsButton;

  padding: 9px 7px;

  color: var(--text90);
  width: 230px;
}
