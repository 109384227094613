.title {
  font-family: 'Euclid Circular A';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: var(--text100);
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.subtitle {
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 14px;
  color: var(--text60);
}

.workspaceList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  row-gap: 10px;
}

.workspaceListItem {
  display: flex;
  width: 100%;
  height: 34px;
  padding: 9px 6px 9px 12px;
  align-items: center;
  gap: 8px;
  position: relative;
  cursor: pointer;
  border-radius: var(--border-radius);
  background: var(--white, #fff);
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--text70, #69696b);

  svg path {
    stroke: var(--text70, #69696b);
  }

  &:hover {
    color: var(--text-icons-black, #29282c);
    background: var(--background-gray-hover, #ededee);
    svg path {
      stroke: var(--text-icons-black, #29282c);
    }
  }

  &__black {
    svg path {
      stroke: none;
    }
    color: var(--text-icons-black, #29282c);

    &:hover {
      svg path {
        stroke: none;
      }
    }
  }
}
