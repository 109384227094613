.sidebar {
  width: var(--sidebarWidth);
  height: 100vh;
  padding: 20px 10px 10px 10px;

  background: var(--background-gray);

  display: flex;
  flex-flow: column;

  position: relative;
  z-index: var(--zIndexModals);
}

.shadow {
  box-shadow: var(--box-shadow2);
  background: var(--white);
}

.sidebarHiddenIcon {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
  transform: rotate(180deg);

  z-index: var(--zIndexModals);

  cursor: pointer;

  &:hover {
    border-radius: var(--border-radius);
    background: var(--background-gray-hover);
  }
}
