.tooltip {
  border-radius: 4px;
  box-shadow: var(--box-shadow2);

  &__default {
    background-color: var(--text90);
    color: var(--white);
    font-weight: 500;
    font-size: 12px;
    padding: 6px;
    border-radius: 4px;
    word-break: break-all;
  }

  &__light {
    background-color: var(--white);
  }
}