.title {
  font-family: 'Euclid Circular A';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;

  color: var(--text100);

  width: 100%;

  text-align: center;
}

.form {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.googleButton {
  margin-top: 30px;
}

.googleIcon {
  margin-right: 8px;
}

.or {
  text-align: center;

  width: 100%;

  color: var(--text50);

  font-size: 14px;
  line-height: 14px;

  margin-top: 20px;
  margin-bottom: 30px;
}

.passwordInputWrapper {
  margin-top: 12px;
}

.actionsWrapper {
  margin-top: 12px;

  width: 100%;

  display: flex;

  justify-content: space-between;
  align-items: center;
}

.rememberWrapper {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.rememberShadowCheckbox {
  width: 20px;
  height: 20px;

  opacity: 0;

  position: absolute;
}

.rememberTitle {
  margin-left: 8px;

  font-size: 14px;
  line-height: 14px;

  color: var(--text60);
}

.rememberCheckboxWrapper {
  height: 20px;
  width: 20px;
  position: relative;
  border-radius: var(--border-radius);

  box-sizing: border-box;

  border-color: var(--text20);
  border-width: 0px;

  display: flex;

  justify-content: center;
  align-items: center;

  background-color: var(--white);

  &:hover {
    background-color: var(--text20);
  }
}

.rememberCheckboxWrapperIsRemember {
  border-width: 1px;

  background-color: var(--background-dark-blue);

  &:hover {
    background-color: var(--background-dark-blue-hover);
  }
}

.rememberCheckboxIcon {
  width: 15px;
}

.forgotPassword {
  text-decoration-line: underline;
  cursor: pointer;
  color: var(--text60);
  font-size: 14px;
  line-height: 14px;

  &:hover {
    color: var(--background-dark-hover);
  }
}

.loginButton {
  margin-top: 36px;
}

.errorMessage {
  margin-top: 6px;

  color: var(--dark-pink);

  font-size: 11px;
  line-height: 14px;
}

.noAccountWrapper {
  display: flex;
  align-items: center;
  align-self: center;

  margin-top: 48px;

  color: var(--text60);

  font-size: 14px;
  line-height: 17.75px;
}

.noAccountLinkWrapper {
  text-decoration-line: underline;

  &:hover {
    color: var(--background-dark-hover);
  }
}
