:root {
  --white: #ffffff;
  --text5: #f4f4f4;
  --text10: #eaeaea;
  --text20: #d4d4d5;
  --text30: #bfbfc0;
  --text40: #a9a9ab;
  --text50: #949395;
  --text60: #7f7e80;
  --text70: #69696b;
  --text80: #545356;
  --text90: #3e3e41;
  --text100: #29282c;
  --dark-pink: #ff5065;
  --dark-blue: #6598ff;
  --light-blue: #cfdfff;
  --link-color: #2554F6;
  --accent-blue: #9FBFFF;
  --primary-hover: #4a86ff;
  --font-blue: #4a86ff;

  --background-active: #ECF0FB;
  --background-gray: #f7f7f8;
  --background-gray-hover: #ededee;

  --background-dark-blue: var(--dark-blue);
  --background-dark-blue-hover: #4a86ff;
  --background-accent-blue: #9fbfff1f;



  --background-dark: #545356;
  --background-dark-hover: #3e3e41;

  --background-light: var(--text10);
  --background-dark-hover: var(--text20);

  --box-shadow: 0px 1px 6px #00000033;
  --box-shadow2: 0px 0px 8px 0px #7f7e804d;
  --box-shadow3: -1px 20px 15px 0px rgba(127, 126, 128, 0.15);
  --box-shadow4: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
  --note-box-shadow: 0px 4px 6px 0px rgba(72, 71, 77, 0.50);

  --border-radius: 6px;
  --border-radius-sm: 4px;

  --text-icons-black: #29282c;

  --font-family: 'Euclid Circular A';
  --font-style: normal;
  --font-weight: 400;
  --font-size: 14px;
  --line-height: 16px;

  --zIndexWhiteboard: 20;
  --zIndexModals: 30;

  --sidebarWidth: 260px;

  --text-editor-row-width: 720px;

  --gray-50: #fafafa;
  --gray-100: #f5f5f5;
  --gray-200: #eeeeee;
  --gray-300: #e0e0e0;
  --gray-400: #bdbdbd;
  --gray-500: #9e9e9e;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
}