.server-error {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.server-error__container {
  display: flex;
  flex-direction: column;

  align-items: center;
}

.server-error__title {
  font-family: 'Euclid Circular A';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;

  display: flex;
  align-items: flex-end;
  text-align: center;


  color: #29282c;
}

.server-error__message {
  height: 36px;
  width: 330px;

  font-family: 'Euclid Circular A';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  margin-top: 10px;

  color: #545356;
}

.server-error__button {
   margin-top: 46px;
}
