@import './normalize.scss';
@import './vars.scss';
@import './tldraw.scss';

@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&family=Rammetto+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Rammetto+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Graduate&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gravitas+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nixie+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rammetto+One&display=swap');

// * {
//   font-family: var(--font-family);
//   font-style: var(--font-style);
//   font-weight: var(--font-weight);
//   font-size: var(--font-size);
//   line-height: var(--line-height);
// }

// #root {
//   height: 100vh;

//   overflow: hidden;
// }

.actions-list {
  width: 210px;

  display: flex;
  flex-direction: column;

  gap: 2px;

  padding: 5px;

  position: fixed;

  background: #ffffff;
  z-index: var(--zIndexModals);

  box-shadow:
    0 0 #0000,
    0 0 #0000,
    0px 4px 20px rgba(0, 0, 0, 0.15);
}

.action-item__icon {
  margin: 0px 8px 0px 12px;

  width: 16px;
  height: 16px;

  &>path {
    stroke: #7f7e80;
  }
}

.workspace-details {
  min-width: 800px;
  width: 100%;
  min-height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;

  .head-panel {
    position: sticky;
    top: 0;
    z-index: 1;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    padding: 0px 20px;
    background-color: var(--white);
  }

  .bread-crumbs {
    font-family: 'Euclid Circular A';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: var(--text90);
    width: 50%;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .bread-crumbs__withShowSidebarButton {
    @extend .bread-crumbs;

    margin-left: 30px;
  }

  .actions {
    width: 50%;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    z-index: 1;
  }

  .workspace {
    width: 100%;
    flex: 1;

    box-sizing: border-box;

    overflow-y: auto;
    overflow-x: hidden;

    -ms-overflow-style: none;
    scrollbar-width: none;

    display: flex;
    align-items: center;
    flex-direction: column;

    padding-bottom: 260px;

    &::-webkit-scrollbar {
      display: none;
    }

    .title {
      font-family: 'Euclid Circular A';
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 40px;
      color: #212121;

      margin-top: 115px;
      margin-bottom: 48px;

      width: var(--text-editor-row-width);
    }
  }
}

.description-details {
  min-width: 758px;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;
}

.row {
  display: flex;
  flex-direction: row;
}

.comments__row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.comment__icon {
  width: 38px;
  height: 38px;
  padding: 10px;
  border-radius: var(--border-radius);

  &:hover {
    background: var(--background-gray);
  }

  &_opened {
    width: 38px;
    height: 38px;
    padding: 10px;
    border-radius: var(--border-radius);
    background-color: var(--background-gray);

    &:hover {
      background: var(--background-gray);
    }
  }

  &_disabled {
    width: 38px;
    height: 38px;
    padding: 10px;
    border-radius: var(--border-radius);
    background: transparent;
    color: var(--text60);
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.icon-button {
  width: 24px;
  height: 24px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button:hover {
  background-color: var(--text5);
  border-radius: var(--border-radius);
}