.sidebarBottomActions {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 2px;
}

.sidebarBottomActionsButton {
  display: flex;
  width: 240px;
  height: 34px;
  padding: 9px 6px 9px 12px;
  align-items: center;
  gap: 8px;

  position: relative;

  color: var(--text70);
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  border-radius: var(--border-radius);

  cursor: pointer;

  & > svg path {
    stroke: var(--text70);
  }

  &:hover {
    color: var(--text-icons-black);
    background: var(--background-gray-hover);
    & > svg path {
      stroke: var(--text-icons-black);
    }
  }
}

.sidebarBottomActionsButtonDisabled {
  @extend .sidebarBottomActionsButton;

  pointer-events: none;

  color: var(--text40);

  & > svg path {
    stroke: var(--text40);
  }
}

.sidebarBottomActionsButtonInvite {
  @extend .sidebarBottomActionsButton;

  justify-content: center;

  color: var(--text90);
  background: var(--background-gray-hover)
}